<template>
  <section class="section container">
    <h1>Minhas inscrições</h1>

    <hr />

    <b-table
      v-if="enrolls[0]"
      :data="enrolls"
      default-sort-direction="asc"
      :striped="true"
      :hoverable="true"
    >
      <template slot-scope="props">
        <b-table-column field="course.name" label="Curso" sortable>{{
          props.row.course.name
        }}</b-table-column>

        <b-table-column
          field="course.instructor.name"
          label="Instrutor"
          sortable
          >{{ props.row.course.instructor.name }}</b-table-column
        >

        <b-table-column field="active" label="Status" sortable>{{
          props.row.active ? 'Ativo' : 'Inativo'
        }}</b-table-column>

        <b-table-column
          field="paymentStatus"
          label="Status de pagamento"
          sortable
          >{{ translator(props.row.paymentStatus) }}</b-table-column
        >

        <b-table-column
          field="paymentMethod"
          label="Meio de pagamento"
          sortable
          >{{ props.row.paymentMethod.toUpperCase() }}</b-table-column
        >

        <b-table-column field="extra.boletoUrl" label="Link Boleto" sortable
          ><a
            v-if="props.row.extra && props.row.extra.boletoUrl"
            :href="props.row.extra.boletoUrl"
            target="_blank"
            >Boleto</a
          >
          <span v-else>-</span>
        </b-table-column>
        <b-table-column field="extra.boletoUrl"  sortable>
          <router-link
            v-if="props.row.availableToBuy"
            class="button is-link"
            tag="button"
            :to="'/detalhe-cursos/' + props.row.course._id"
            >Comprar</router-link
          >
          <!-- <span v-else>-</span> -->
        </b-table-column>
      </template>
    </b-table>

    <div v-show="!enrolls[0]">
      <h1 class="oops">
        <b>OOPS!</b> você não tem nenhuma inscrição.
        <router-link tag="span" to="/cursos">clique aqui </router-link>para
        comprar um curso!
      </h1>
    </div>
  </section>
</template>

<script>
import Axios from 'axios';

export default {
  data() {
    return {
      enrolls: []
    };
  },
  methods: {
    getEnrolls() {
      Axios.get(`/v1/enrolls/me`)
        .then(data => {
          this.enrolls = data.data;
        })
        .catch(erro => {
          console.error(erro);
        });
    },
    translator(item) {
      switch (item) {
        case 'authorized':
          return 'Transação foi autorizada.';
        case 'paid':
          return 'Transação paga.';
        case 'refunded':
          return 'Transação estornada completamente.';
        case 'waiting_payment':
          return 'Transação aguardando pagamento.';
        case 'pending_refund':
          return 'Transação do tipo Boleto e que está aguardando confirmação do estorno solicitado.';
        case 'refused':
          return 'Transação recusada, não autorizada.';
        case 'analyzing':
          return 'Transação encaminhada para a análise manual feita por um especialista em prevenção a fraude.';
        case 'pending_review':
          return 'Transação pendente de revisão manual por parte do lojista. Uma transação ficará com esse status por até 48 horas corridas.';
        default:
          return '-';
      }
    }
  },
  mounted() {
    this.getEnrolls();
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.oops {
  span {
    color: #23d160;
    font-weight: bold;
    transition: all 0.2s ease-in-out;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
